//// overlay styles which need to applied to body (in case of appendTo=body)
//@use 'styles/ng-datepicker';
//@use 'styles/ng-dropdown-panel';
//@use 'styles/ng-multiselect-panel';
//@use 'styles/ng-overlaypanel';
//@use 'styles/ng-tooltip';
//
//// styles for primeng components
//// source: node_modules/primeng/resources/themes/saga-green/theme.css
//// to avoid problems in cms we apply all styles to kfd container (if possible)
//@use 'styles/ng-general';
//@use 'styles/ng-button';
//@use 'styles/ng-checkbox';
//@use 'styles/ng-dropdown';
//@use 'styles/ng-inputtext';
//@use 'styles/ng-inputgroup';
//@use 'styles/ng-message';
//@use 'styles/ng-multiselect';
//@use 'styles/ng-selectbutton';


.kfd-container {
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
  Segoe UI Emoji, Segoe UI Symbol;
  --focus-ring: 0 0 0.2rem 0.1rem var(--ci-primary-color-lighter);
  --color-success: #439446;
  --color-success-light: #c8e6c9;
  --color-error: #f44336;
  --color-error-light: #ffcdd2;
  --color-warn: #fbc02d;
  --color-warn-light: #ffecb3;
  --color-info: #0891cf;
  --color-info-light: #b3e5fc;
  --color-border: #e5e8eb;
  --gray-600: #495057;
  --font-size: 1rem;
  --text-color: var(--gray-600);
  --border-radius: 3px;

  --gray-50: #edeef0;
  --gray-100: #dbdde1;
  --gray-200: #b7bbc3;
  --gray-300: #949aa6;
  --gray-400: #707888;
  --gray-500: #4c566a;
  --gray-600: #3d4555;
  --gray-700: #2e3440;
  --gray-800: #1e222a;
  --gray-900: #0f1115;
}
